/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter Var';
  src: url('https://tailwindcss.com/_next/static/media/Inter-roman-latin.var.18496762.woff2')
    format('woff2');
  font-weight: 100 900;
}
